html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html,
body,
#__next {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover,
a:focus:not(.focus-visible) {
  text-shadow: -0.06ex 0 currentColor, 0.06ex 0 currentColor;
}
